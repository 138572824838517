<template>
    <form @submit.prevent="onSubmit">
      <div class="merge-contacts">
        <div class="multiselect-wrap--medium" v-if="schoolContracts.length === 0" >
          <label class="multiselect__label">
            {{ $t('SEND_CONTRACT.SELECT.EMPTY_LABEL') }}
          </label>
        </div>
        <div class="multiselect-wrap--medium" v-if="schoolContracts.length > 0">
          <label class="multiselect__label">
            {{ $t('SEND_CONTRACT.SELECT.TITLE') }}
          </label>
          <multiselect
            v-model="selectedEnrollment"
            :options="schoolContracts"
            label="student_name"
            track-by="id"
            :show-labels="false"
            :placeholder="$t('SEND_CONTRACT.SELECT.PLACEHOLDER')"
            :max-height="150"
            open-direction="top"
          />
          <span v-if="!$v.selectedEnrollment.invalidDueDate" class="error">
            {{ $t('SEND_CONTRACT.SELECT.INVALID_ENROLLMENT_DUE_DATE') }}
          </span>
        </div>
      </div>
      <div class="footer">
        <woot-button variant="clear" @click.prevent="onCancel">
          {{ $t('SEND_CONTRACT.FORM.CANCEL') }}
        </woot-button>
        <woot-button type="submit" :is-loading="isRequestingContract" :disabled="$v.$invalid">
          {{ $t('SEND_CONTRACT.FORM.SUBMIT') }}
        </woot-button>
      </div>
    </form>
  </template>
  
  <script>
  import alertMixin from 'shared/mixins/alertMixin';
  import { required } from 'vuelidate/lib/validators';
    
  export default {
    components: {  },
    mixins: [alertMixin],
    props: {
      isRequestingContract: {
        type: Boolean,
        default: false,
      },
      enrollments: {
        type: Array,
        default: () => [],
      },
    },
    validations() {
      return {
        selectedEnrollment: {
          required,
          invalidDueDate: this.enrollmentDueDateValidation
        }
      }
    },
    data() {
      return {
        selectedEnrollment: null,
      };
    },
    computed: {
      schoolContracts() {
        return this.enrollments.filter(enrollment => enrollment?.school_contract?.annuity);
      },
    },
    methods: {
      onSubmit() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.$emit('submit', this.selectedEnrollment);
      },
      onCancel() {
        this.selectedEnrollment = null;
        this.$emit('cancel');
      },
      enrollmentDueDateValidation(enrollment) {
        if(enrollment?.tuition_due_date){
          const enrollmentDueDate = new Date(enrollment.tuition_due_date + 'T00:00:00-03:00');
          const currentDay = new Date();
          currentDay.setHours(0, 0, 0, 0);

          if (enrollmentDueDate < currentDay) {
            return false;
          }
        }
        return true;
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .footer {
    margin-top: var(--space-medium);
    display: flex;
    justify-content: flex-end;
  }
  .error {
    @apply text-sm text-red-300 dark:text-red-500;
  }
  </style>
  