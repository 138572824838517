export const MACRO_ACTION_TYPES = [
  {
    key: 'assign_team',
    label: 'MACROS.EDITOR.LABELS.ASSIGN_TEAM',
    inputType: 'search_select',
  },
  {
    key: 'assign_agent',
    label: 'MACROS.EDITOR.LABELS.ASSIGN_AGENT',
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    label: 'MACROS.EDITOR.LABELS.ADD_LABEL',
    inputType: 'multi_select',
  },
  {
    key: 'remove_label',
    label: 'MACROS.EDITOR.LABELS.REMOVE_LABEL',
    inputType: 'multi_select',
  },
  {
    key: 'remove_assigned_team',
    label: 'MACROS.EDITOR.LABELS.REMOVE_ASSIGNED_TEAM',
    inputType: null,
  },
  {
    key: 'send_email_transcript',
    label: 'MACROS.EDITOR.LABELS.SEND_EMAIL_TRANSCRIPT',
    inputType: 'email',
  },
  {
    key: 'mute_conversation',
    label: 'MACROS.EDITOR.LABELS.MUTE_CONVERSATION',
    inputType: null,
  },
  {
    key: 'snooze_conversation',
    label: 'MACROS.EDITOR.LABELS.SNOOZE_CONVERSATION',
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    label: 'MACROS.EDITOR.LABELS.RESOLVE_CONVERSATION',
    inputType: null,
  },
  {
    key: 'send_attachment',
    label: 'MACROS.EDITOR.LABELS.SEND_ATTACHMENT',
    inputType: 'attachment',
  },
  {
    key: 'send_message',
    label: 'MACROS.EDITOR.LABELS.SEND_MESSAGE',
    inputType: 'textarea',
  },
  {
    key: 'add_private_note',
    label: 'MACROS.EDITOR.LABELS.ADD_PRIVATE_NOTE',
    inputType: 'textarea',
  },
  {
    key: 'change_priority',
    label: 'Change Priority',
    inputType: 'search_select',
  },
];
