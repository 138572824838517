<template>
  <div class="column content-box">
    <report-filter-selector
      :show-agents-filter="true"
      :show-business-hours-switch="false"
      @filter-change="onFilterChange"
    />
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="arrow-download"
      @click="openDownloadModal"
    >
      {{ $t('PAUSE_REPORTS.DOWNLOAD') }}
    </woot-button>
    <pause-table :page-index="pageIndex" @page-change="onPageNumberChange" />
    <woot-modal :show.sync="downloadModalOpen" :on-close="onCloseDownloadModal">
      <download-report-modal v-if="downloadModalOpen" :title="$t('PAUSE_REPORTS.DOWNLOAD')" :show="downloadModalOpen" :on-close="onCloseDownloadModal" :on-choose="downloadReports" />
    </woot-modal>
  </div>
</template>
<script>
import PauseTable from './components/PauseReportTable';
import ReportFilterSelector from './components/FilterSelector';
import { mapGetters } from 'vuex';
import { generateFileName } from '../../../../helper/downloadHelper';
import { REPORTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import DownloadReportModal from './components/DownloadReportModal.vue';

export default {
  name: 'PauseReports',
  components: {
    PauseTable,
    ReportFilterSelector,
    DownloadReportModal,
  },
  data() {
    return { 
      pageIndex: 1,
      from: 0, 
      to: 0, 
      userIds: [],

      downloadModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      accountId: 'getCurrentAccountId',
    }),
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    getAllData() {
      this.getResponses();
    },
    getResponses() {
      this.$store.dispatch('pauseReports/get', {
        page: this.pageIndex,
        from: this.from,
        to: this.to,
        user_ids: this.userIds,
        account_id: this.accountId,
      });
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getResponses();
    },
    onDateRangeChange({ from, to }) {
      console.log("onDateRangeChange")

      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: 'pause',
        });
      }
      this.from = from;
      this.to = to;
      this.getAllData();
    },
    onFilterChange({ from, to, selectedAgents }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: 'pause',
        });
      }

      this.from = from;
      this.to = to;
      this.userIds = selectedAgents.map(el => el.id);

      this.getAllData();
    },
    onAgentsFilterChange(agents) {
      this.userIds = agents.map(el => el.id);
      this.getAllData();
      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'agent',
        reportType: 'pause',
      });
    },
    openDownloadModal(){
      this.downloadModalOpen = true
    },
    onCloseDownloadModal(){
      this.downloadModalOpen = false
    },
    downloadReports(fileType) {
      const type = 'pause';
      this.$store.dispatch('pauseReports/downloadPauseReports', {
        from: this.from,
        to: this.to,
        user_ids: this.userIds,
        fileName: generateFileName({ type, to: this.to, fileType }),
        fileType
      });
    },
  },
};
</script>
