<template>
  <div class="school-contract--panel">
    <div v-if="!uiFlags.isFetching" class="contact-conversation__wrap">
      <div class="row">
        <label>
          {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.LABEL') }}
        </label>
        <multiselect
          v-model="enrollment"
          track-by="id"
          label="student_name"
          :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.PLACEHOLDER')"
          selected-label
          :select-label="
            $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.SELECT_PLACEHOLDER')
          "
          :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.REMOVE')"
          :max-height="160"
          :options="updatedEnrollments"
          :allow-empty="true"
          :option-height="104"
          @select="chooseEnrollment"
        />
      </div>
      <div v-if="enrollment && !contracts" class="no-label-message">
        <span>
          {{ $t('CONTACT_PANEL.CONTRACTS.NO_RECORDS_FOUND') }}
        </span>
      </div>
      <div v-else class="custom-attributes--panel">
        <div class="row">
          <div v-if="contracts" class="medium-12 columns">
            <div
              v-if="contracts.urls && Object.keys(contracts.urls).length > 0"
            >
              <div v-if="contracts.urls.contract_url_contractee">
                <h4
                  class="conv-details--item__label text-block-title label-message"
                >
                  <span class="item__title">
                    {{ $t('CONTACT_PANEL.CONTRACTS.HIRED_LABEL') }}
                  </span>
                </h4>

                <a
                  rel="noreferrer noopener nofollow"
                  class="link"
                  :href="contracts.urls.contract_url_contractee"
                  target="_blank"
                >
                  {{ contracts.urls.contract_url_contractee }}
                </a>
              </div>

              <div v-if="contracts.urls.contract_url_contractor">
                <h4
                  class="conv-details--item__label text-block-title label-message"
                >
                  <span class="item__title">
                    {{ $t('CONTACT_PANEL.CONTRACTS.CONTRACTEE_LABEL') }}
                  </span>
                </h4>

                <a
                  rel="noreferrer noopener nofollow"
                  class="link"
                  :href="contracts.urls.contract_url_contractor"
                  target="_blank"
                >
                  {{ contracts.urls.contract_url_contractor }}
                </a>
              </div>

              <div
                v-if="
                  contracts.urls.contract_witnesses &&
                    contracts.urls.contract_witnesses.length > 0
                "
              >
                <div
                  v-for="(witness, index) in contracts.urls.contract_witnesses"
                  :key="witness"
                  class="medium-12 columns"
                >
                  <div>
                    <h4
                      class="conv-details--item__label text-block-title label-message"
                    >
                      <span class="item__title">
                        {{ $t('CONTACT_PANEL.CONTRACTS.WITNESS_LABEL') }}
                        {{ currentLabelValue(index) }}:
                      </span>
                    </h4>

                    <a
                      rel="noreferrer noopener nofollow"
                      class="link"
                      :href="witness"
                      target="_blank"
                    >
                      {{ witness }}
                    </a>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  contracts.document_signed_url &&
                    contracts.document_signed_url.length > 0
                "
              >
                <h4
                  class="conv-details--item__label text-block-title label-message"
                >
                  <span class="item__title">
                    {{ $t('CONTACT_PANEL.CONTRACTS.CONTRACT_SIGNED') }}
                  </span>
                </h4>

                <woot-button
                  color-scheme="primary"
                  icon="download"
                  size="small"
                  @click="onClickDownload(contracts)"
                >
                  {{ $t('CONTACT_PANEL.CONTRACTS.CONTRACT_LABEL') }}
                </woot-button>
              </div>
            </div>
            <div v-else class="custom-attributes--panel">
              <span>
                {{ $t('CONTACT_PANEL.CONTRACTS.NO_RECORDS_FOUND') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <spinner v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
    conversationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      enrollment: null,
      contracts: null,
      labelValue: 1,
    };
  },
  computed: {
    contactContracts() {
      return this.$store.getters['contacts/getContracts'](this.contactId);
    },
    updatedEnrollments() {
      return this.$store.getters['schoolEnrollments/getSchoolEnrollments'];
    },
    ...mapGetters({
      uiFlags: 'contactConversations/getUIFlags',
    }),
  },
  watch: {
    contactId(newContactId, prevContactId) {
      this.enrollment = null;
      this.contracts = null;
      this.labelValue = 1;
      this.$store.dispatch('schoolEnrollments/get', this.contactId);
    },
  },
  mounted() {
    this.$store.dispatch('schoolEnrollments/get', this.contactId);
    this.enrollment = null;
    this.contracts = null;
    this.labelValue = 1;
  },
  methods: {
    currentLabelValue(index) {
      return this.labelValue + index;
    },
    async chooseEnrollment(selectedEnrollment) {
      const contracts = await this.$store.dispatch(
        'contacts/getContracts',
        this.contactId
      );

      const filteredContracts = contracts.filter(contract => {
        return contract.school_enrollment_id === selectedEnrollment.id;
      });

      const sortedContracts = filteredContracts.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      if (sortedContracts.length > 0) {
        const latestContract = sortedContracts[0];
        if (latestContract.urls && latestContract.urls.length > 0) {
          latestContract.urls = JSON.parse(latestContract.urls);
        }
        this.contracts = latestContract;
      } else {
        this.contracts = null;
      }
    },
    onClickDownload(contract) {
      window.open(contract.document_signed_url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-attributes--panel {
  .conversation--attribute {
    border-bottom: 1px solid var(--color-border-light);
  }

  &.odd {
    .conversation--attribute {
      &:nth-child(2n + 1) {
        background: var(--s-25);
      }
    }
  }

  &.even {
    .conversation--attribute {
      &:nth-child(2n) {
        background: var(--s-25);
      }
    }
  }
}

::v-deep .conversation {
  padding-right: 0;
  .conversation--details {
    padding-left: var(--space-small);
  }
}

.no-label-message {
  margin-bottom: var(--space-normal);
  color: var(--b-500);
}

.label-message {
  margin-top: var(--space-normal);
}

.button-download {
  font-size: 1.4rem;
}
</style>
