import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import PauseReports from '../../api/pauseReports';
import { downloadCsvFile, downloadXlsxFile } from '../../helper/downloadHelper';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { REPORTS_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getPauseReports(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  get: async function getResponses(
    { commit },
    { page = 1, from, to, user_ids, account_id } = {}
  ) {
    commit(types.SET_PAUSE_REPORTS_RESPONSE_UI_FLAG, { isFetching: true });
    try {
      const response = await PauseReports.get({
        page,
        from,
        to,
        user_ids,
        account_id,
      });
      commit(types.SET_PAUSE_REPORTS_RESPONSE, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_PAUSE_REPORTS_RESPONSE_UI_FLAG, { isFetching: false });
    }
  },
  downloadPauseReports(_, params) {
    return PauseReports.download(params).then(response => {
      if (params.fileType === "xlsx"){
        downloadXlsxFile(params.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'pause_xlsx',
        });
      } else {
        downloadCsvFile(params.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'pause_csv',
        });
      }
    });
  },
};

export const mutations = {
  [types.SET_PAUSE_REPORTS_RESPONSE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_PAUSE_REPORTS_RESPONSE]: MutationHelpers.set,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
