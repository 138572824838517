<template>
    <div>
      <div class="settings--content">
        <settings-section
          :title="$t('SCHOOL_INBOX_CONFIGURATION.TITLE')"
          :sub-title="
            $t('SCHOOL_INBOX_CONFIGURATION.SUBTITLE')
          "
        >
          <div class="school-settings--content columns">
            <multiselect
                class="input"

                v-model="selectedSchoolAccount"
                :options="schoolAccounts"
                track-by="id"
                label="school_name"
    
                :placeholder="$t('SCHOOL_INBOX_CONFIGURATION.SCHOOL_ACCOUNT_SELECT.PLACEHOLDER')"
                selected-label
                :select-label="$t('SCHOOL_INBOX_CONFIGURATION.SCHOOL_ACCOUNT_SELECT.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_INBOX_CONFIGURATION.SCHOOL_ACCOUNT_SELECT.REMOVE')"

                @select="updateInboxSchoolAccount"
                />
          </div>
        </settings-section>
        <settings-section
          v-if="currentClicksignHook"
          :title="$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_CONFIGURATION.TITLE')"
          :sub-title="
            $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_CONFIGURATION.SUBTITLE')
          "
        >
          <div class="school-settings--content flex-col w-full gap-2">
            <label>
              {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_CONFIGURATION.TEMPLATE_KEY')}} 
              <input :value="clicksignTemplateKey" type="text" disabled/>
            </label>
            <label>
              {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_CONFIGURATION.TOKEN')}}
              <input :value="clicksignToken" type="text" disabled/>
            </label>
          </div>
        </settings-section>

        <settings-section
          v-if="currentClicksignHook"
          :title="$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.TITLE')"
          :sub-title="
            $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SUBTITLE')
          "
        >
          <div class="school-settings--content flex-col w-full gap-1">
            <div>
              <p class="text-woot-500 dark:text-woot-500 pb-1 text-xl not-italic font-medium">
                {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_1') }}
              </p>

              <p class="pb-1 text-sm not-italic font-medium">
                {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_1_DESCRIPTION') }}
              </p>
            </div>
            <label>
              {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_NAME')}} 
              <input v-model="clicksignSignerName" type="text"/>
            </label>

            <label>
              {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_DOCUMENT')}} 
              <input v-model="clicksignSignerDocument" type="text" v-mask="'###.###.###-##'"/>
            </label>

            <label>
              {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_BIRTHDAY')}} 
              <input v-model="clicksignSignerBirthday" type="date"/>
            </label>

            <label>
              {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_EMAIL')}} 
              <input v-model="clicksignSignerEmail" type="text"/>
            </label>
        
            <label>
                {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_KEY')}}
                <div class="flex gap-1 relative">
                  <div class="flex-none">
                    <woot-button color-scheme="primary" @click="sendSignerToClicksign">
                      {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SEND_BUTTON') }}
                    </woot-button>
                  </div>
                  <div class="flex-1">
                    <input v-model="clicksignSignerKey" type="text" readonly/>
                  </div>
                  <woot-button
                    size="small"
                    @click="onCopySignerKey"
                    class="absolute top-0 right-0"
                  >
                    {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.COPY_BUTTON') }}
                  </woot-button>
                </div> 
            </label>
            
            <div>
              <p class="text-woot-500 dark:text-woot-500 pb-1 text-xl not-italic font-medium mt-5">
                {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_2') }}
              </p>

              <p class="pb-1 text-sm not-italic font-medium">
                {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_2_DESCRIPTION') }}
              </p>
            </div>

            <div>
              <a :href="clicksignAutosignFormUrl" target="_blank"><u>{{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_2_LINK_LABEL') }}</u></a>
            </div>

            <div class="mt-5">
              <div class="flex flex-row">
                <label>
                  {{$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_SECRET')}} 
                </label>
                <fluent-icon
                  v-tooltip.top-start="$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_SECRET_TOOLTIP')"
                  icon="info"
                  size="14"
                  class="mt-px"
                />
              </div>
              <input v-model="clicksignSignerSecret" type="text"/>
            </div>

            <div>
              <input
                v-model="clicksignEnableAutoSign"
                type="checkbox"
              />
              <label>
                {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.ENABLE_AUTO_SIGN') }}
              </label>
            </div>
            
            <div>
              <woot-button color-scheme="primary" @click="updateHook" :disabled="!currentClicksignHook.settings || !clicksignSignerSecret">
                {{ $t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SAVE_BUTTON') }}
              </woot-button>
            </div>

          </div>
        </settings-section>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import alertMixin from 'shared/mixins/alertMixin';
  import inboxMixin from 'shared/mixins/inboxMixin';
  import SettingsSection from '../../../../../components/SettingsSection';
  import { copyTextToClipboard } from 'shared/helpers/clipboard';

  export default {
    components: {
      SettingsSection,
    },
    mixins: [inboxMixin, alertMixin],
    props: {
      inbox: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        selectedSchoolAccount: null,

        clicksignSignerEmail:null,
        clicksignSignerName: null,
        clicksignSignerDocument: null,
        clicksignSignerBirthday: null,

        clicksignSignerSecret: null,
        clicksignEnableAutoSign: false,
      };
    },
   
    computed: {
        ...mapGetters({
            getAccount: 'accounts/getAccount',
            accountId: 'getCurrentAccountId',
        }),

        clicksignAutosignFormUrl() {
          return window.chatwootConfig.clicksignAutosignFormUrl;
        },

        clicksignAccountIntegrations() {
          return this.$store.getters['integrations/getIntegration']('clicksign');
        },

        currentClicksignHook() {
          return this.clicksignAccountIntegrations.hooks.find(hook => hook.inbox.id === this.inbox.id)
        },

        clicksignTemplateKey() {
          return this.currentClicksignHook?.settings.template_key
        },

        clicksignToken() {
          return this.currentClicksignHook?.settings.token;
        },

        clicksignSignerKey() {
          return this.currentClicksignHook?.settings?.signer_key;
        },

        currentAccount() {
            return this.getAccount(this.accountId);
        },

        schoolAccounts() {
            return this.currentAccount?.school_accounts;
        }, 
    },

    mounted() {
      this.selectedSchoolAccount = this.inbox?.school_account;

      this.clicksignSignerEmail = this.currentClicksignHook?.settings?.signer_email;
      this.clicksignSignerName = this.currentClicksignHook?.settings?.signer_name;
      this.clicksignSignerDocument = this.currentClicksignHook?.settings?.signer_documentation;
      this.clicksignSignerBirthday = this.currentClicksignHook?.settings?.signer_birthday;
      this.clicksignSignerSecret = this.currentClicksignHook?.settings?.signer_secret;
      this.clicksignEnableAutoSign = this.currentClicksignHook?.settings?.enable_auto_sign
    },

    methods: {
      async updateInboxSchoolAccount(selectedSchoolAccount) {
        try {
          const payload = {
            id: this.inbox.id,
            school_account_id: selectedSchoolAccount.id
          };

          await this.$store.dispatch('inboxes/updateInbox', payload);
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        } catch (error) {
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
        }
      },

      async sendSignerToClicksign() {
        try {
          await this.$store.dispatch('integrations/sendSignerToClicksign', {
            appId: 'clicksign',
            id: this.currentClicksignHook.id,
            token: this.clicksignToken,
            template_key: this.clicksignTemplateKey,
            signer: {
              email: this.clicksignSignerEmail,
              name: this.clicksignSignerName,
              documentation: this.clicksignSignerDocument,
              birthday: this.clicksignSignerBirthday
            }
          });

          this.showAlert(this.$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_KEY_SUCCESS_MESSAGE'));
        } catch (error) {
          this.showAlert(this.$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_KEY_ERROR_MESSAGE'));
        }
      },

      async updateHook() {
        try {
          await this.$store.dispatch('integrations/updateHook', {
            id: this.currentClicksignHook.id,
            settings:{
              ...this.currentClicksignHook.settings,
              signer_secret: this.clicksignSignerSecret,
              enable_auto_sign: this.clicksignEnableAutoSign,
            },
          });
          
          this.showAlert(this.$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SECRET_SUCCESS_MESSAGE'));
        } catch (error) {
          this.showAlert(this.$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.SECRET_ERROR_MESSAGE'));
        }
      },

      async onCopySignerKey(e) {
        e.preventDefault();
        await copyTextToClipboard(this.clicksignSignerKey);
        bus.$emit('newToastMessage', this.$t('SCHOOL_INBOX_CONFIGURATION.CLICKSIGN_AUTOMATIC_SIGNATURE.COPY_SUCCESSFUL'));
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .school-settings--content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: var(--space-small);
  
    .input {
      flex: 1;
      margin-right: var(--space-small);
      ::v-deep input {
        margin-bottom: 0;
      }
    }

    .disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  </style>
  