<template>
  <div class="csat--table-container">
    <ve-table
      :fixed-header="true"
      :columns="columns"
      :table-data="tableData"
    />
    <div v-show="!tableData.length" class="csat--empty-records">
      {{ $t('PAUSE_REPORTS.NO_RECORDS') }}
    </div>
  </div>
</template>
<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';

function formatTime(seconds) {
  const horas = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  let formatedTime = '';

  if (horas > 0) {
    formatedTime += `${horas}h `;
  }

  if (minutes > 0) {
    formatedTime += `${minutes}min `;
  }

  formatedTime += `${secondsLeft}s`;

  return formatedTime;
}

export default {
  components: {
    VeTable,
  },
  filters: {
    formatDate(date) {
      const parts = date.split('-');
      const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
      return formattedDate;
    },
  },
  mixins: [timeMixin, rtlMixin],
  props: {
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'pauseReports/getUIFlags',
      pauseReports: 'pauseReports/getPauseReports',
    }),
    columns() {
      return [
        {
          field: 'account_name',
          key: 'account_name',
          title: this.$t('PAUSE_REPORTS.TABLE.HEADER.ACCOUNT_NAME'),
          align: 'center',
          width: 100,
        },
        {
          field: 'agent_name',
          key: 'agent_name',
          title: this.$t('PAUSE_REPORTS.TABLE.HEADER.AGENT_NAME'),
          align: 'center',
          width: 100,
        },
        {
          field: 'description',
          key: 'description',
          title: this.$t('PAUSE_REPORTS.TABLE.HEADER.DESCRIPTION'),
          align: 'center',
          width: 100,
        },
        {
          field: 'total_paused_time',
          key: 'total_paused_time',
          title: this.$t('PAUSE_REPORTS.TABLE.HEADER.TOTAL_PAUSED_TIME'),
          align: 'center',
          width: 100,
          renderBodyCell: ({ row }) => {
            const formatedTime = formatTime(row.total_paused_time);
            return <div>{formatedTime}</div>;
          },
        },
        {
          field: 'created_date',
          key: 'created_date',
          title: this.$t('PAUSE_REPORTS.TABLE.HEADER.CREATED_DATE'),
          align: 'center',
          width: 100,
          renderBodyCell: ({ row }) => {
            const formattedDate = this.$options.filters.formatDate(
              row.created_date
            );
            return <div>{formattedDate}</div>;
          },
        },
      ];
    },
    tableData() {
      return this.pauseReports.map(response => ({
        account_name: response.account_name,
        agent_name: response.agent_name,
        description: response.description,
        total_paused_time: response.total_paused_time,
        created_date: response.created_date,
      }));
    },
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
.csat--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    background: var(--white);

    &::v-deep {
      .ve-table-container {
        border-radius: var(--border-radius-normal);
        border: 1px solid var(--color-border) !important;
      }

      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-normal) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-small) var(--space-normal) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .emoji-response {
    font-size: var(--font-size-large);
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.csat--empty-records {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  border-top: 0;
  color: var(--b-600);
  display: flex;
  font-size: var(--font-size-small);
  height: 20rem;
  justify-content: center;
  margin-top: -1px;
  width: 100%;
}

.csat--timestamp {
  color: var(--b-400);
  font-size: var(--font-size-small);
}
</style>
