<template>
  <div>
    <div class="buttonsForm">
      <button class="small-4" :class="{ 'button clear': formMode !== 1, 'button': formMode === 1 }"
        @click.prevent="defineMode(1)">{{ $t('SCHOOL_CONTACT_FORM.BUTTONS.CONTACT') }}</button>
      <button class="small-4" v-bind:disabled="!checkEnrollmentsPermission()"
        :class="{ 'button clear': formMode !== 2, 'button': formMode === 2, 'disabled': !checkEnrollmentsPermission() }"
        @click.prevent="hasSchoolContact() && defineMode(2)">{{ $t('SCHOOL_CONTACT_FORM.BUTTONS.ENROLLMENT') }}</button>
      <button class="small-4" v-bind:disabled="!checkEnrollmentsPermission()"
        :class="{ 'button clear': formMode !== 3, 'button': formMode === 3, 'disabled': !checkEnrollmentsPermission() }"
        @click.prevent="hasSchoolContact() && defineMode(3)">{{ $t('SCHOOL_CONTACT_FORM.BUTTONS.CONTRACT') }}</button>
    </div>

    <school-contact-form v-if="formMode === 1" v-bind="$attrs" @success="onSuccess" @cancel="onCancel"
      :selectedSchoolLabelsList="selectedSchoolLabels" />
    <school-enrollments-form v-if="formMode === 2" v-bind="$attrs" @cancel="onCancel"
      :selectedSchoolLabels="selectedSchoolLabels" />
    <school-contract-form v-if="formMode === 3" v-bind="$attrs" @cancel="onCancel"
      :selectedSchoolLabels="selectedSchoolLabels" />

  </div>
</template>
<script>
import VueMask from 'v-mask';
import Vue from 'vue';
import SchoolContactForm from './SchoolContactForm.vue';
import SchoolContractForm from './SchoolContractForm.vue';
import SchoolEnrollmentsForm from './SchoolEnrollmentsForm.vue';
Vue.use(VueMask);


export default {
  components: {
    SchoolContactForm,
    SchoolEnrollmentsForm,
    SchoolContractForm
  },

  data() {
    return {
      formMode: 1,
    }
  },

  watch: {
    contactId() {
      this.formMode = 1;
    }
  },

  computed: {
    contact() {
      return this.$attrs.contact
    },
    contactId() {
      return this.contact?.id
    },
    selectedSchoolLabels() {
      return this.contact?.school_contact?.school_label_list;
    },
  },

  methods: {
    defineMode(value) {
      this.formMode = value;
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    hasSchoolContact() {
      return !!this.$attrs.contact?.school_contact;
    },
    checkAllowedSchoolTagsArePresent() {
      if (this.selectedSchoolLabels?.length > 0) {
        return [
          '09_finalização_de_dados',
          '10_assinatura_de_contrato',
          '11_matrícula',
          '12_contrato_recusado',
          '13_matrícula_manual',
          '14_contrato_parcial',
        ].some(label => this.selectedSchoolLabels.includes(label));
      }
      return false
    },
    checkEnrollmentsPermission() {
      return this.checkAllowedSchoolTagsArePresent() && this.hasSchoolContact()
    }
  },

  mounted() {
    if (this.contact) {
      this.$store.dispatch('schoolEnrollments/get', this.contact.id);
    }
  }
}

</script>
<style scoped lang="scss">
.buttonsForm {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid;
  border-color: rgb(184, 182, 182);
  padding: 10px;
}

.button {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>