var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-md bg-yellow-50 border border-yellow-200 dark:border-slate-700 dark:bg-slate-800 px-6 py-4 mb-4"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0 mt-0.5"},[_c('fluent-icon',{staticClass:"text-yellow-500 dark:text-yellow-400",attrs:{"icon":"alert","size":"24"}})],1),_vm._v(" "),_c('div',{staticClass:"ml-3"},[_c('p',{staticClass:"text-base font-semibold text-yellow-900 dark:text-yellow-500 mb-1"},[_vm._v("\n        "+_vm._s(_vm.$t('INTEGRATION_SETTINGS.SLACK.SELECT_CHANNEL.ATTENTION_REQUIRED'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"text-sm text-yellow-800 dark:text-yellow-600 mt-2"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
            _vm.formatMessage(
              _vm.useInstallationName(
                _vm.errorDescription,
                _vm.globalConfig.installationName
              ),
              false
            )
          ),expression:"\n            formatMessage(\n              useInstallationName(\n                errorDescription,\n                globalConfig.installationName\n              ),\n              false\n            )\n          "}]})])])]),_vm._v(" "),(!_vm.hasConnectedAChannel)?_c('div',{staticClass:"ml-8 mt-2"},[(!_vm.availableChannels.length)?_c('woot-submit-button',{attrs:{"button-class":"smooth small warning","loading":_vm.uiFlags.isFetchingSlackChannels,"button-text":_vm.$t('INTEGRATION_SETTINGS.SLACK.SELECT_CHANNEL.BUTTON_TEXT'),"spinner-class":"warning"},on:{"click":_vm.fetchChannels}}):_c('div',{staticClass:"inline-flex"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedChannelId),expression:"selectedChannelId"}],staticClass:"h-8 border-yellow-300 border mr-4 text-xs leading-4 py-1",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedChannelId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("\n          "+_vm._s(_vm.$t('INTEGRATION_SETTINGS.SLACK.SELECT_CHANNEL.OPTION_LABEL'))+"\n        ")]),_vm._v(" "),_vm._l((_vm.availableChannels),function(channel){return _c('option',{key:channel.id,domProps:{"value":channel.id}},[_vm._v("\n          #"+_vm._s(channel.name)+"\n        ")])})],2),_vm._v(" "),_c('woot-submit-button',{attrs:{"button-class":"smooth small success","button-text":_vm.$t('INTEGRATION_SETTINGS.SLACK.SELECT_CHANNEL.UPDATE'),"spinner-class":"success","loading":_vm.uiFlags.isUpdatingSlack},on:{"click":_vm.updateIntegration}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }