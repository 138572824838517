<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openFormPopup()"
    >
      {{ $t('REASONS_TO_BE_BUSY.HEADER_BTN_TXT') }}
    </woot-button> 

    <!-- List REASONS_TO_BUSY -->
    <div class="row">
      <div class="small-8 columns with-right-space ">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('REASONS_TO_BE_BUSY.LOADING')"
        />
        <div v-else>
          <p v-if="!reasonsList.length">
            {{ $t('REASONS_TO_BE_BUSY.LIST.404') }}
          </p>
          <table v-else class="woot-table" >
            <thead>
              <th
                v-for="thHeader in $t('REASONS_TO_BE_BUSY.LIST.TABLE_HEADER')"
                :key="thHeader"
              >
                {{ thHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="reason in reasonsList" :key="reason.id">
      
                <td>
                  <span class="agent-name">
                    {{ reason.description }}
                  </span>
                </td>

                <td >
                  <span >{{ secondsToInputTime(reason.acceptable_time) }}</span>
                </td>

                
                <!-- Actions -->
                <td class="button-wrapper" style="justify-content: start;">
                  
                    <woot-button
                      v-tooltip.top="$t('REASONS_TO_BE_BUSY.EDIT.BUTTON_TEXT')"
                      variant="smooth"
                      size="tiny"
                      color-scheme="secondary"
                      icon="edit"
                      @click="openFormPopup(reason)"
                    />
                    <woot-button
                      v-tooltip.top="$t('REASONS_TO_BE_BUSY.DELETE.BUTTON_TEXT')"
                      variant="smooth"
                      color-scheme="alert"
                      size="tiny"
                      icon="dismiss-circle"
                      class-names="grey-btn"
                      :is-loading="loading[reason.id]"
                      @click="openDeletePopup(reason)"
                    />
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('REASONS_TO_BE_BUSY.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    
    <woot-modal :show.sync="showFormPopup" :on-close="hideFormPopup">
      <reason-to-be-busy-form :on-close="hideFormPopup" :reason="currentReason" />
    </woot-modal>
    
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('REASONS_TO_BE_BUSY.DELETE.CONFIRM.TITLE')"
      :message="$t('REASONS_TO_BE_BUSY.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import ReasonToBeBusyForm from './ReasonToBeBusyForm.vue';
import { secondsToInputTime } from './reasonToBeBusyHelpers';

export default {
  components: {
    ReasonToBeBusyForm,
    Thumbnail,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      loading: {},
      showFormPopup: false,
      showDeletePopup: false,
      currentReason: {},
    };
  },
  computed: {
    ...mapGetters({
      reasonsList: "reasonsToBeBusy/getReasons",
      uiFlags: 'reasonsToBeBusy/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    deleteConfirmText() {
      return `${this.$t('REASONS_TO_BE_BUSY.DELETE.CONFIRM.YES')} ${
        this.currentReason?.description
      }`;
    },
    deleteRejectText() {
      return `${this.$t('REASONS_TO_BE_BUSY.DELETE.CONFIRM.NO')} ${
        this.currentReason?.description
      }`;
    },
    deleteMessage() {
      return ` ${this.currentReason?.description}?`;
    },
  },
  mounted() {
    this.$store.dispatch('reasonsToBeBusy/get');

  },
  methods: {
    secondsToInputTime(seconds) {
      return seconds && secondsToInputTime(seconds);
    },
    openFormPopup(reason = null) {
      this.currentReason = reason;
      this.showFormPopup = true;
    },
    hideFormPopup() {
      this.currentReason = null;
      this.showFormPopup = false;
    },

    openDeletePopup(reason) {
      this.showDeletePopup = true;
      this.currentReason = reason;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentReason.id] = true;
      this.closeDeletePopup();
      this.deleteReasonToBusy(this.currentReason.id);
    },
    async deleteReasonToBusy(id) {
      try {
        await this.$store.dispatch('reasonsToBeBusy/delete', id);
        this.showAlert(this.$t('REASONS_TO_BE_BUSY.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('REASONS_TO_BE_BUSY.DELETE.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
