export const ATTRIBUTE_MODELS = [
  {
    id: 0,
    option: 'ATTRIBUTES_MGMT.TABS.CONVERSATION',
  },
  {
    id: 1,
    option: 'ATTRIBUTES_MGMT.TABS.CONTACT',
  },
];

export const ATTRIBUTE_TYPES = [
  {
    id: 0,
    option: 'ATTRIBUTES_MGMT.TYPES.TEXT',
  },
  {
    id: 1,
    option: 'ATTRIBUTES_MGMT.TYPES.NUMBER',
  },
  {
    id: 4,
    option: 'ATTRIBUTES_MGMT.TYPES.BOOLEAN',
  },
  {
    id: 5,
    option: 'ATTRIBUTES_MGMT.TYPES.DATE',
  },
  {
    id: 6,
    option: 'ATTRIBUTES_MGMT.TYPES.LIST',
  },
  {
    id: 7,
    option: 'ATTRIBUTES_MGMT.TYPES.CHECKBOX',
  },
];
