<template>
  <div class="column content-box">
    <div style="display: flex;">
      <div>
        <p>
          {{ $t('REPORT.DURATION_FILTER_LABEL') }}
        </p>
        <report-filter-selector
          :type="type"
          :show-business-hours-switch="false"
          @date-range-change="onDateRangeChange"
          @filter-change="onFilterChange"
        />
      </div>

      <div style="margin-left: 10px; width: 250px;">
        <p>
          {{ $t('LABEL_REPORTS.HEADER_FUNNEL.TAGGABLE_TYPE.FILTER_LABEL') }}
        </p>

        <div class="multiselect-wrap--small">
          <multiselect
            v-model="taggableType"
            class="no-margin"
            track-by="ID"
            label="LABEL"
            :preselect-first="true"
            :placeholder="
              $t('LABEL_REPORTS.HEADER_FUNNEL.TAGGABLE_TYPE.SELECT_PLACEHOLDER')
            "
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            deselect-label=""
            :options="taggableOptions"
            :searchable="false"
            :allow-empty="false"
            @input="fetchAllData"
            :show-labels="false"
          />
        </div>
      </div>
    </div>
    <v-chart class="chart" :option="option" />
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { FunnelChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import ReportFilterSelector from './components/FilterSelector';
import { mapGetters } from 'vuex';
import { REPORTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import configMixin from 'shared/mixins/configMixin';

use([
  CanvasRenderer,
  FunnelChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: 'LabelsFunnelReport',
  components: {
    VChart,
    ReportFilterSelector,
  },
  mixins: [configMixin],
  props: {
    type: {
      type: String,
      default: 'account',
    },
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  data() {
    return {
      taggableType: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      allLabels: 'labels/getAllLabels',
    }),
    taggableOptions() {
      if (this.isKedu) {
        return this.$t(
          'SCHOOL_REPORTS.LABEL_REPORTS.HEADER_FUNNEL.TAGGABLE_TYPE.OPTIONS'
        );
      }
      return this.$t('LABEL_REPORTS.HEADER_FUNNEL.TAGGABLE_TYPE.OPTIONS');
    },
    labelReport() {
      return this.$store.getters.getLabelFunnelReports || {};
    },
    option() {
      if (this.labelReport.isFetching) {
        return {};
      }
      let data = [];
      let legends = [];
      const reportData = this.labelReport.data;
      reportData?.label_funnel_count?.map(element => {
        Object.entries(element).map(([key, value]) => {
          const hasLabel = this.allLabels.find(label => label.title === key);
          if (hasLabel) {
            const color = hasLabel.color || '#000000';
            data.push({ name: key, value: value, color });
            legends.push(key);
          }
        });
      });
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: legends,
        },
        series: [
          {
            name: 'Marcadores',
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside',
              formatter: '{b} - {c}({d}%)',
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              opacity: 0.7,
              color: ({ data }) => data.color,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data,
          },
        ],
      };
    },
  },

  methods: {
    fetchAllData() {
      if (this.selectedFilter || this.isSelectedFilterDisabled) {
        const { from, to } = this.selectedFilter;
        const payload = {
          from,
          to,
        };

        if (this.selectedFilter) {
          payload.id = this.selectedFilter.id;
        }
        payload.type = this.type;
        payload.metric = 'label_funnel_count';
        payload.taggableType = this.taggableType?.ID;
        this.$store.dispatch('fetchAccountLabelFunnelReport', payload);
      }
    },

    onFilterChange(payload) {
      if (payload) {
        this.selectedFilter = payload;
        this.fetchAllData();
      }
    },
    onDateRangeChange({ from, to }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: 'label-funnel',
        });
      }

      this.from = from;
      this.to = to;
      this.fetchAllData();
    },
  },
};
</script>
