
  <div>
    <pie-chart :data="chartData" :options="chartOptions" :width="400" :height="400"></pie-chart>
  </div>


<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ['#36a2eb', '#ffce56', '#cc65fe', '#ff6384', '#4bc0c0', '#9966ff'],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    data: {
      handler: function(newData) {
        if(newData && this.$data._chart){
          let counts = {}
          for (let i = 0; i < newData.length; i++) {
            let item = newData[i]
            counts[item] = counts[item] ? counts[item] + 1 : 1
          }
          this.chartData.labels = Object.keys(counts)
          this.chartData.datasets[0].data = Object.values(counts)
          this.$data._chart.update()
        }
      },
      immediate: true,
    },
  },
}
</script>
