export const isPersonNameValid = value => {
    const names = value?.split(" ");
    return names && names.length > 1;
}

export function isKeduFieldValid (value, type ) {
    switch (type) {
        case 'schoolClass':
            return isSchoolClassValid(value);
        case 'schoolYear':
            return isSchoolYearValid(value);
        case 'fullname':
            return isPersonNameValid(value);
        case 'street':
            return isStreetValid(value);
        case 'city':
            return isCityValid(value);
        case 'zipCode':
            return isZipCodeValid(value);
        case 'firstDocument':
            return isFirstDocumentValid(value);
        default:
            return false;

    }
}

const isSchoolClassValid = value => {
    const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z\s\-°0-9]*$/;
    return !!value?.match(nameRegex);
}

const isSchoolYearValid = value => {
       const year = new Date().getFullYear();
       return parseInt(value) >= parseInt(year);
}

const isZipCodeValid = value => {
    const nameRegex = /^\d{8}$/;
    value = value.replace(/[^0-9]/g, '');
    return !!value?.match(nameRegex);
}

const isCityValid = value => {
    const nameRegex = /^[a-zA-ZÀ-ÿ ]{3,}$/;
    return !!value?.match(nameRegex);
}

const isStreetValid = value => {
    const nameRegex = /^(?=.*\b\w{2,}\b)[\wÀ-ÿ0-9 .-]+$/;
    return !!value?.match(nameRegex);
}

const isFirstDocumentValid = value => {
    let cpf = value.replace(/[^0-9]/g, '');
    
    if((cpf.length !== 11) ||
        (cpf == "00000000000") || 
        (cpf == "11111111111") || 
        (cpf == "22222222222") || 
        (cpf == "33333333333") || 
        (cpf == "44444444444") || 
        (cpf == "55555555555") || 
        (cpf == "66666666666") || 
        (cpf == "77777777777") || 
        (cpf == "88888888888") || 
        (cpf == "99999999999")) {
        return false;
    }

    let add = 0;    
    for (let i=0; i < 9; i ++)       
        add += parseInt(cpf.charAt(i)) * (10 - i);  
    let rev = 11 - (add % 11);  
    if (rev == 10 || rev == 11)     
        rev = 0;    
    if (rev != parseInt(cpf.charAt(9)))     
            return false;       

    add = 0;    
    for (let i = 0; i < 10; i ++)        
        add += parseInt(cpf.charAt(i)) * (11 - i);  
    rev = 11 - (add % 11);  
    if (rev == 10 || rev == 11) 
        rev = 0;    
    if (rev != parseInt(cpf.charAt(10)))
        return false;       
    return true;   

}
