<template>
  <woot-reports
    key="campaign-reports"
    type="campaign"
    getter-key="labels/getCampaignLabels"
    action-key="labels/get"
    :reportKeys="reportKeys"
    :download-button-label="$t('CAMPAIGN_REPORTS.DOWNLOAD_CAMPAIGN_REPORTS')"
  />
</template>

<script>
import WootReports from './components/WootReports';

export default {
  components: {
    WootReports,
  },
  computed: {
    reportKeys() {
      return [
        'CONVERSATIONS',
        'ANSWERED_CONVERSATIONS',
        'RESOLUTION_COUNT',
        'INCOMING_MESSAGES',
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'RESOLUTION_TIME',
      ];
    },
  },
};
</script>
