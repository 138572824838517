<template>
    <transition name="modal-fade">
      <div
        v-if="show"
        class="flex justify-start bg-modal dark:bg-modal z-[9990] h-full left-0 fixed top-0 w-full skip-context-menu"
        transition="modal"
        @click="onBackDropClick"
      >
        <div class="w-48 skip-context-menu" @click.stop>
          <slot />
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      closeOnBackdropClick: {
        type: Boolean,
        default: true,
      },
      show: Boolean,
      onClose: {
        type: Function,
        required: true,
      },
      modalType: {
        type: String,
        default: 'centered',
      },
    },
    mounted() {
      document.addEventListener('keydown', e => {
        if (this.show && e.code === 'Escape') {
          this.onClose();
        }
      });
    },
    methods: {
      close() {
        this.onClose();
      },
      onBackDropClick() {
        if (this.closeOnBackdropClick) {
          this.onClose();
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  

  </style>
  