import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ accountLabels: 'labels/getLabels', schoolLabels: 'labels/getSchoolLabels'}),
    savedLabels() {
      return this.$store.getters['conversationLabels/getConversationLabels'](
        this.conversationId
      );
    },
    activeLabels() {
      return this.accountLabels.filter(({ title }) =>
        this.savedLabels.includes(title)
      );
    },
    activeSchoolLabels() {
      return contactSchoolLabels => this.schoolLabels.filter(({ title }) =>
        contactSchoolLabels.includes(title)
      );
    },
    inactiveLabels() {
      return this.accountLabels.filter(
        ({ title }) => !this.savedLabels.includes(title)
      );
    },
  },
  methods: {
    addLabelToConversation(value) {
      const result = this.activeLabels.map(item => item.title);
      result.push(value.title);
      this.onUpdateLabels(result);
    },
    removeLabelFromConversation(value) {
      const updatedLabels = this.activeLabels.filter(label => label.title !== value || label.label_type === 'campaign');
      this.onUpdateLabels(updatedLabels.map(label => label.title));
    },
    async onUpdateLabels(selectedLabels) {
      this.$store.dispatch('conversationLabels/update', {
        conversationId: this.conversationId,
        labels: selectedLabels,
      });
    },
  },
};
