export default {
    computed: {
        moneyMask() {
            return value => {
                const numbers = value.replace(/[^0-9]/g, '');
                let mask = [];
                if (numbers.length === 1) {
                    mask = [0, ',', 0, /[0-9]/];
                } else if (numbers.length === 2) {
                    mask = [0, ',', /[0-9]/, /[0-9]/];
                } else if (numbers.length === 3) {
                    mask = [/[0-9]/, ',', /[0-9]/, /[0-9]/];
                } else if (numbers.length > 3) {
                    let currentMask = [',', /[0-9]/, /[0-9]/];
                    let integersNumber = numbers.length - 2;
                    while (integersNumber > 3) {
                        currentMask = ['.', /[0-9]/, /[0-9]/, /[0-9]/].concat(currentMask);
                        integersNumber -= 3;
                    }

                    let array = Array(integersNumber).fill().map(() => /[0-9]/).concat(currentMask);
                    array[0] = /[1-9]/;
                    mask = array;
                }
                const currencySign = this.$t('SCHOOL_CONTACT_FORM.FORM.CURRENCY_SIGN');
                return Array.from(currencySign).concat(mask);
            }
        },
        moneyMaskToDecimal() {
            return value => {
                return parseFloat(value.replace(/[^\d,]/g, '').replace(",", '.')) || 0
            };
        }
    }
};