<template>
    <div class="mb-1">
        <hr class="m-1"/>
        <div class="flex justify-center items-center gap-2"> 
            <fluent-icon
                class="mr-0.5 rtl:ml-0.5 rtl:mr-0"
                :icon="buttonIcon"
                size="14"
            />
            {{ button.text || $t('WHATSAPP_TEMPLATES.PARSER.COPY_CODE_LABEL') }} 
        </div>
    </div>
</template>
<script>
export default {
    props: {
        button: {
            type: Object,
            default: () => { },
        },
    },

    computed: {
        buttonIcon() {
            switch (this.button.type.toLowerCase()) {
                case 'phone_number':
                    return 'call';
                case 'url':
                    return 'link';
                case 'copy_code':
                    return 'copy';
                case 'quick_reply':
                    return 'arrow-reply';
                default:
                    return '';
            }
        }
    }
}

</script>
<style></style>