<template>
  <woot-reports
    key="agent-reports"
    type="agent"
    getter-key="agents/getAgents"
    action-key="agents/get"
    :reportKeys="reportKeys"
    :download-button-label="$t('REPORT.DOWNLOAD_AGENT_REPORTS')"
  />
</template>

<script>
import WootReports from './components/WootReports';

export default {
  components: {
    WootReports,
  },
  computed: {
    reportKeys() {
      return [
        'CONVERSATIONS',
        'RESOLUTION_COUNT',
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'RESOLUTION_TIME',
      ];
    },
  }
};
</script>
