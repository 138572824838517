<template>
    <modal :show.sync="show" :on-close="onClose">
      <div class="column content-box">
        <woot-modal-header :header-title="title">
          <p>
            {{ $t('EXPORT_REPORT.DESC') }}
          </p>
        </woot-modal-header>
        <div class="row modal-content">
          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-button
                @click="downloadReportsCSV"
                id="download-btn"
              >
                {{ $t('EXPORT_REPORT.DOWNLOAD_CSV_LABEL') }}
              </woot-button>
              <woot-button
                @click="downloadReportsXLSX"
              >
                {{ $t('EXPORT_REPORT.DOWNLOAD_XLSX_LABEL') }}
              </woot-button>
              <button class="button clear" @click.prevent="onClose">
                {{ $t('EXPORT_REPORT.CANCEL') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  import Modal from 'dashboard/components/Modal';
  import alertMixin from 'shared/mixins/alertMixin';
  import configMixin from 'shared/mixins/configMixin';
  
  export default {
    components: {
      Modal,
    },
    mixins: [alertMixin, configMixin],
    props: {
      title: {
        type: String
      },
      show: {
        type: Boolean,
        default: false
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      onChoose: {
        type: Function,
        default: (type) => {},
      },
    },
    methods: {
      downloadReportsCSV() {
        this.onChoose("csv");
      },
  
      downloadReportsXLSX() {
        this.onChoose("xlsx");
      }
    },
  };
  </script>
  