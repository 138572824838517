export function secondsToInputTime(seconds) {
    let decimalHours = seconds / 3600;
    let wholeHours = Math.floor(decimalHours);
  
    let remainingSeconds = seconds - wholeHours * 3600;
    let decimalMinutes = remainingSeconds / 60;
    let wholeMinutes = Math.floor(decimalMinutes);
  
    // format the string in the format hh:mm
    let hoursStr = wholeHours.toString().padStart(2, '0');
    let minutesStr = wholeMinutes.toString().padStart(2, '0');
    let hourMinuteStr = `${hoursStr}:${minutesStr}`;
  
    return hourMinuteStr;
  }
  
export function inputTimeToSeconds(inputTimeValue) {
    let [hoursStr, minutesStr] = inputTimeValue.split(':');
  
    let hoursSeconds = parseInt(hoursStr) * 3600;
    let minutesSeconds = parseInt(minutesStr) * 60;
  
    let totalSeconds = hoursSeconds + minutesSeconds;
  
    return totalSeconds;
  }
  