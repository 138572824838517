<template>
    <div class="reply-wrap" :class="customClass + replyWrapClass">
        <div class="py-2 px-4">
            <div v-if="data.content">
                <bubble-text :message="data.content" :display-quoted-button="false" />
            </div>
            <div v-if="hasAttachments">
                <div v-for="attachment in attachments" :key="attachment.id">
                    <bubble-image-audio-video v-if="isAttachmentImageVideoAudio(attachment.file_type)"
                        :attachment="attachment" />
                    <bubble-location v-else-if="attachment.file_type === 'location'" :latitude="attachment.coordinates_lat"
                        :longitude="attachment.coordinates_long" :name="attachment.fallback_title" />
                    <bubble-contact v-else-if="attachment.file_type === 'contact'" :name="data.content"
                        :phone-number="attachment.fallback_title" />
                    <bubble-file v-else :url="attachment.data_url" />
                </div>
            </div>
            <div class="text-woot-100 dark:text-woot-100 text-xxs">
                {{ readableTime }}
            </div>
        </div>
    </div>
</template>

<script>

import BubbleText from './bubble/Text';
import BubbleImageAudioVideo from './bubble/ImageAudioVideo';
import BubbleLocation from './bubble/Location';
import BubbleContact from './bubble/Contact';
import BubbleFile from './bubble/File';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import timeMixin from 'dashboard/mixins/time';

export default {
    mixins: [timeMixin],
    components: {
        BubbleText,
        BubbleImageAudioVideo,
        BubbleLocation,
        BubbleContact,
        BubbleFile
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        customClass: {
            type: String,
            default: '',
        }
    },

    computed: {
        replyWrapClass() {
            if (this.data.message_type === MESSAGE_TYPE.OUTGOING) {
                return 'reply-outgoing'
            } else {
                return 'reply-incoming'
            }
        },
        hasAttachments() {
            return !!(this.data.attachments && this.data.attachments.length > 0);
        },
        attachments() {
            return this.data?.attachments.map(attachment => ({
                ...attachment,
                sender: this.data.sender || {},
                created_at: this.data.created_at || '',
            }));
        },
        readableTime() {
            const createdAt = this.data.created_at;
            return this.messageTimestamp(createdAt, 'LLL d, h:mm a');
        },
    },

    methods: {
        isAttachmentImageVideoAudio(fileType) {
            return ['image', 'audio', 'video'].includes(fileType);
        },
    },
}
</script>

<style lang="scss">
.reply-wrap {
    @apply max-w-[20rem] p-0.5 rounded-md opacity-50 pointer-events-none;

    .image {
        @apply max-w-[20rem] p-0.5;
    }

    .video {
        @apply max-w-[20rem] p-0.5;
    }
}

.reply-outgoing {
    @apply bg-woot-600 dark:bg-woot-600 text-white dark:text-white;
}

.reply-incoming {
    @apply bg-slate-75 dark:bg-slate-700 text-slate-800 dark:text-slate-100;
}
</style>