<template>
  <div class="timer-popup" v-if="showTimer">
    <div class="timer-popup__content">
        <h2 class="timer-popup__title">{{ formattedTitle }}</h2>
        <div class="timer-popup__timer-label" :class="{ 'timer-popup__timer-label--expired': timeExpired }">{{ formatTime(time) }}</div>
        <br/>
        <woot-button class="timer-popup__button" @click="stopTimer">{{$t('SIDEBAR.RETURN_FROM_PAUSE')}}</woot-button>
    </div>
  </div>
</template>

<style>
.timer-popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.timer-popup__content {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.timer-popup__timer-label {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.timer-popup__timer-label--expired {
  color: red;
}

.timer-popup__button {
  align-items: center !important;
  text-align: center !important;
  width: 40% !important;
}

</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Timer'
    },
    duration: {
      type: Number,
      default: 0 // default duration of 0 seconds (count up from 0)
    },
    label: {
      type: String,
      default: ''
    },
    currentAccountId: Number,
  },
  data() {
    return {
      time: this.duration,
      timerId: null,
      showTimer: false,
    }
  },
  computed: {
    formattedTitle() {
      return this.label || this.title;
    },
    timeExpired() {
      return this.time < 0;
    }
  },
  methods: {
    startTimer() {
      this.showTimer = true;
      this.timerId = setInterval(() => {
        this.time--;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerId);
      this.showTimer = false;
      const newAvailability = {
          availability: "online",
          reasonId: null,
          account_id: this.currentAccountId,
      };
      this.$store.dispatch('updateAvailability', newAvailability);
    },
    formatTime(time) {
      const seconds = Math.abs(time) % 60;
      const minutes = Math.floor(Math.abs(time) / 60) % 60;
      const hours = Math.floor(Math.abs(time) / 3600);
      const prefix = time < 0 ? '-' : '';
      return `${prefix}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  mounted() {
    this.startTimer();
  }
}
</script>
