import ApiClient from './ApiClient';
import { payloadToFormData } from '../helper/APIHelper'

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  create(data) {
    let payload = data;
    if(data.template_info?.attachments && data.template_info.attachments.length !== 0) {
      payload = payloadToFormData({campaign: data, ...data});
    }
    return super.create(payload);
  }

}

export default new CampaignsAPI();
