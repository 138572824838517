<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="reason ? $t('REASONS_TO_BE_BUSY.FORM.EDIT.TITLE') : $t('REASONS_TO_BE_BUSY.FORM.ADD.TITLE')" :header-content="$t('REASONS_TO_BE_BUSY.FORM.DESC')" />

      <form class="row" @submit.prevent="onFormSubmit()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.description.$error }">
            {{ $t('REASONS_TO_BE_BUSY.FORM.DESCRIPTION.LABEL') }}
            <input v-model.trim="description" type="text" :placeholder="$t('REASONS_TO_BE_BUSY.FORM.DESCRIPTION.PLACEHOLDER')"
              @input="$v.description.$touch" />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.acceptable_time.$error }">
            {{ $t('REASONS_TO_BE_BUSY.FORM.ACCEPTABLE_TIME.LABEL') }}
            <input v-model.trim="acceptable_time" type="time" 
              @input="$v.acceptable_time.$touch" />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button :disabled="
              $v.acceptable_time.$invalid ||
              $v.description.$invalid ||
              uiFlags.isCreating
            " :button-text="$t('REASONS_TO_BE_BUSY.FORM.SUBMIT_BUTTON_TEXT')" :loading="uiFlags.isCreating" />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('REASONS_TO_BE_BUSY.FORM.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { secondsToInputTime, inputTimeToSeconds } from './reasonToBeBusyHelpers';

export default {
  props: {
    onClose: {
      type: Function,
      default: () => { },
    },
    reason: {
      id: {
        type: Number,
      },
      description: {
        type: String,
      },
      acceptable_time: {
        type: Number,
      },
    },
  },
  data() {
    return {
      description: this.reason?.description,
      acceptable_time: this.reason?.acceptable_time && secondsToInputTime(this.reason.acceptable_time),
      vertical: 'bottom',
      horizontal: 'center',
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'reasonsToBeBusy/getUIFlags',
    }),
  },
  validations: {
    description: {
      required,
      minLength: minLength(1),
    },
    acceptable_time: {
    
    },
  },

  methods: {
    showAlert(message, action) {
      bus.$emit('newToastMessage', message, action);
    },
    async addReasonToBeBusy() {
      try {
        await this.$store.dispatch('reasonsToBeBusy/create', {
          description: this.description,
          acceptable_time: this.acceptable_time && inputTimeToSeconds(this.acceptable_time),

        });
        this.showAlert(this.$t('REASONS_TO_BE_BUSY.FORM.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('REASONS_TO_BE_BUSY.FORM.ADD.API.ERROR_MESSAGE'));
      }
    },
    async editReasonToBeBusy() {
      try {
        await this.$store.dispatch('reasonsToBeBusy/update', {
          id: this.reason.id,
          description: this.description,
          acceptable_time: this.acceptable_time && inputTimeToSeconds(this.acceptable_time),

        });
        this.showAlert(this.$t('REASONS_TO_BE_BUSY.FORM.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('REASONS_TO_BE_BUSY.FORM.EDIT.API.ERROR_MESSAGE'));
      }
    },
    onFormSubmit() {
      if(this.reason){
        this.editReasonToBeBusy()
      } else {
        this.addReasonToBeBusy()
      }
    },
  },
};
</script>
