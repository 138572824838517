<template>
    <modal :show.sync="show" :on-close="onClose">
        <div class="column content-box">
            <woot-modal-header :header-title="$t('EXPORT_SCHOOL_CONTACT.TITLE')">
                <p>
                    {{ $t('EXPORT_SCHOOL_CONTACT.DESC') }}
                </p>
            </woot-modal-header>
            <div class="modal-content">
                <div>
                    {{ $t('EXPORT_SCHOOL_CONTACT.CONTACT_TITLE') }}
                    <div class="modal-footer">
                        <div class="medium-12 columns">
                            <woot-button :disabled="uiFlags.isCreating"
                                @click="downloadContactReportsCSV">
                                {{ $t('EXPORT_SCHOOL_CONTACT.CSV_LABEL') }}
                            </woot-button>
                            <woot-button :disabled="uiFlags.isCreating"
                                @click="downloadContactReportsXLSX">
                                {{ $t('EXPORT_SCHOOL_CONTACT.XLSX_LABEL') }}
                            </woot-button>
                        </div>
                    </div>
                </div>

                <div class="mt-2">
                    {{ $t('EXPORT_SCHOOL_CONTACT.ENROLLMENT_TITLE') }}
                    <div class="modal-footer">
                        <div class="medium-12 columns">
                            <woot-button :disabled="uiFlags.isCreating"
                                @click="downloadEnrollmentReportsCSV">
                                {{ $t('EXPORT_SCHOOL_CONTACT.CSV_LABEL') }}
                            </woot-button>
                            <woot-button :disabled="uiFlags.isCreating"
                                @click="downloadEnrollmentReportsXLSX">
                                {{ $t('EXPORT_SCHOOL_CONTACT.XLSX_LABEL') }}
                            </woot-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
  
<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';

export default {
    components: {
        Modal,
    },
    mixins: [alertMixin],
    props: {
        onClose: {
            type: Function,
            default: () => { },
        },
        query: {
            type: Object,
            default: () => { }
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            show: true,
        };
    },
    computed: {
        ...mapGetters({
            uiFlags: 'contacts/getUIFlags',
        }),
    },
    mounted() {
        this.$track(CONTACTS_EVENTS.EXPORT_MODAL_OPEN);
    },
    methods: {
        downloadContactReportsCSV() {
            const fileName = 'contacts-report.csv';
            const params = { fileName, queryFilter: this.query, label: this.label };
            this.$store.dispatch('downloadContactsReportsCSV', params);
        },

        downloadContactReportsXLSX() {
            const fileName = 'contacts-report.xlsx';
            const params = { fileName, queryFilter: this.query, label: this.label };
            this.$store.dispatch('downloadContactsReportsXLSX', params);
        },

        downloadEnrollmentReportsCSV() {
            const fileName = 'school-enrollments-report.csv';
            const params = { fileName, queryFilter: this.query, label: this.label };
            this.$store.dispatch('downloadEnrollmentsReportsCSV', params);
        },

        downloadEnrollmentReportsXLSX() {
            const fileName = 'school-enrollments-report.xlsx';
            const params = { fileName, queryFilter: this.query, label: this.label };
            this.$store.dispatch('downloadEnrollmentsReportsXLSX', params);
        },
    },
};
</script>
  