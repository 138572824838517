/* global axios */
import ApiClient from './ApiClient';

class PauseReportsAPI extends ApiClient {
  constructor() {
    super('pause_reports', { accountScoped: true });
  }

  get({ page, from, to, user_ids } = {}) {
    return axios.get(this.url, {
      params: {
        page,
        since: from,
        until: to,
        sort: '-created_date',
        user_ids,
      },
    });
  }

  download({ from, to, user_ids, fileType: file_type } = {}) {
    return axios.get(`${this.url}/download`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: {
        since: from,
        until: to,
        sort: '-created_date',
        user_ids,
        file_type
      },
      responseType: "blob"
    });
  }
}

export default new PauseReportsAPI();
