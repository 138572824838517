<template>
    <div
      class="w-full h-30 bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0"
    >
      <div class="flex flex-row items-center pr-2">
        <logo
          :source="logoSource"
          :name="installationName"
          :account-id="accountId"
          class="m-4"
        />
        <div class="flex flex-1 flex-row items-center overflow-auto mr-2">
          <div v-for="menuItem in menuItems" 
            class="text-slate-600 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600">
            <primary-nav-item
              :key="menuItem.toState"
              :icon="menuItem.icon"
              :name="menuItem.label"
              :to="menuItem.toState"
              :is-child-menu-active="menuItem.key === activeMenuItem"
            />
          </div>
    
          <primary-nav-item
            v-if="!isACustomBrandedInstance"
            icon="book-open-globe"
            name="DOCS"
            :open-in-new-page="true"
            :to="helpDocsURL"
          />
          <notification-bell @open-notification-panel="openNotificationPanel" />
        </div>
        
        <agent-details @toggle-menu="toggleOptions" />
        <options-menu
          :show="showOptionsMenu"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @key-shortcut-modal="$emit('key-shortcut-modal')"
          @close="toggleOptions"
        />
      </div>
    </div>
  </template>
  <script>
  import Logo from './Logo';
  import PrimaryNavItem from './PrimaryNavItem';
  import OptionsMenu from './OptionsMenu';
  import AgentDetails from './AgentDetails';
  import NotificationBell from './NotificationBell';
  import wootConstants from 'dashboard/constants/globals';
  import { frontendURL } from 'dashboard/helper/URLHelper';
  import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
  
  export default {
    components: {
      Logo,
      PrimaryNavItem,
      OptionsMenu,
      AgentDetails,
      NotificationBell,
    },
    props: {
      isACustomBrandedInstance: {
        type: Boolean,
        default: false,
      },
      logoSource: {
        type: String,
        default: '',
      },
      installationName: {
        type: String,
        default: '',
      },
      accountId: {
        type: Number,
        default: 0,
      },
      menuItems: {
        type: Array,
        default: () => [],
      },
      activeMenuItem: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        helpDocsURL: wootConstants.DOCS_URL,
        showOptionsMenu: false,
      };
    },
    methods: {
      frontendURL,
      toggleOptions() {
        this.showOptionsMenu = !this.showOptionsMenu;
      },
      toggleAccountModal() {
        this.$emit('toggle-accounts');
      },
      toggleSupportChatWindow() {
        window.$chatwoot.toggle();
      },
      openNotificationPanel() {
        this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
        this.$emit('open-notification-panel');
      },
    },
  };
  </script>
  