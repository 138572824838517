<template>
  <div class="w-full">
    <div class="template-input">
      <div v-if="attachedFile" class="flex justify-center mb-2">
          <img v-if="templateType === 'image'" :src="attachedFile.url" class="template-input-media" />
          <video v-if="templateType === 'video'" :src="attachedFile.url" autoplay loop muted
            playsinline class="template-input-media"/>
           <pdf v-if="templateType === 'document'" :src="attachedFile.url" class="w-1/3"></pdf> 
      </div>
      <textarea
        v-model="processedString"
        rows="4"
        readonly
        class="border-0"
      />

      <div v-for="buttonTemplate in templateButtons">
        <template-button :button="buttonTemplate" />
      </div>
    </div>
    <div v-if="hasMedia" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.' + templateType.toUpperCase()) }}
      </p>

      <div class="row gap-1">
        <input type="file"
          ref="fileInput" 
          :accept="allowedFileTypes()"
          @input="onFileUpload"
          style="display: none;"
        />      

        <woot-button
          class-names="button--upload"
          :title="$t('WHATSAPP_TEMPLATES.PARSER.ATTACH_FILE_TOOLTIP')"
          icon="attach"
          emoji="📎"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click.prevent="toggleUploadFile()"
        />

        <div class="-mt-4">
          <attachment-preview
            :attachments="attachedFile && [attachedFile]"
            :remove-attachment="removeAttachment"
          />
        </div>
      </div>
    </div>
    <div v-if="variables" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL') }}
      </p>
      <div
        v-for="(variable, key) in processedParams"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParams[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
      <p v-if="$v.$dirty && $v.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
      </p>
    </div>
    <div v-if="hasButtonVariable" class="template__variables-container">
      <div
        v-for="(button, key) in buttonsWithVariables"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ button.text || $t('WHATSAPP_TEMPLATES.PARSER.COPY_CODE_LABEL') }}
        </span>
        <woot-input
          v-model="buttonParams[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
          v-mask="'NNNNNNNNNNNNNNN'"
        />
      </div>
      <p v-if="$v.$dirty && $v.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
      </p>
    </div>
    <footer v-if="!disableButtons">
      <woot-button variant="smooth" @click="$emit('resetTemplate')">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>
      <woot-button @click="sendMessage">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL') }}
      </woot-button>
    </footer>
  </div>
</template>

<script>
const allKeysRequired = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key]);
};
import { requiredIf } from 'vuelidate/lib/validators';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import TemplateButton from "./TemplateButton.vue"; 
import pdf from 'vue-pdf'
import axios from 'axios';

export default {
  components: {
    pdf,
    AttachmentPreview,
    TemplateButton
  },
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    disableButtons: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    processedParams: {
      requiredIfKeysPresent: requiredIf('variables'),
      allKeysRequired,
    },
    buttonParams: {
      requiredIfKeysPresent: requiredIf('hasButtonVariable'),
      allKeysRequired,
    },
  },
  data() {
    return {
      processedParams: {},
      buttonParams:{},
      attachedFile: null,
    };
  },
  computed: {
    variables() {
      const variables = this.templateString.match(/{{([^}]+)}}/g);
      return variables;
    },
    templateType() {
      return this.template.templateType.toLowerCase();
    },
    templateString() {
      return this.template.components.find(
        component => component.type === 'BODY'
      ).text;
    },
    templateButtons() {
      return this.template.buttons?.map(button => {
        if(button.type.toLowerCase() === "copy_code" || (button.type.toLowerCase() === "url" && button.url.includes("{{1}}"))){
          return {...button, hasVariable: true}
        }

        return button
      }) || []
    },
    hasButtonVariable() {
      return this.templateButtons.find(button => button.hasVariable)
    },
    buttonsWithVariables() {
      return this.templateButtons.filter(button => button.hasVariable)
    },
    processedString() {
      return this.templateString.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        return this.processedParams[variableKey] || `{{${variable}}}`;
      });
    },
    hasMedia() {
      return ['image','video','document'].includes(this.templateType)
    }
  },
  mounted() {
    this.generateVariables();
    this.generateSampleFile();
  },
  methods: {
    getTemplatePayload() {
      let payload = {
        message: this.processedString,
        templateParams: {
          id: this.template.id,
          name: this.template.name,
          category: this.template.category,
          language: this.template.language,
          namespace: this.template.namespace,
          processed_params: {...this.processedParams, ...this.buttonParams}
        },
      };

      if(this.templateButtons.length > 0) {
        payload = {...payload,  additionalAttributes: {
          buttons: this.templateButtons.map(button => ({
              icon: this.buttonIcon(button.type),
              text: button.type.toLowerCase() === "copy_code" ? this.$t('WHATSAPP_TEMPLATES.PARSER.COPY_CODE_LABEL') : button.text
            }))
        }}
      }

      if (this.hasMedia) {
        payload = {...payload,  files: [this.attachedFile.resource]}
      }
      return payload
    },
    sendMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit('sendMessage', this.getTemplatePayload());
    },
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    generateVariables() {
      const matchedVariables = this.templateString.match(/{{([^}]+)}}/g);
      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));
      this.processedParams = variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {});
    },
    async generateSampleFile() {
      if (this.template.sampleMediaUrl) {
        const response = await axios.get(this.template.sampleMediaUrl, { responseType: 'blob' });

        const fileName = `${this.template.templateType.toLowerCase()}_sample_${this.template.name}`;
        const fileType = response.headers['content-type'];
        const fileSize = response.headers['content-length'];

        const file = new File([response.data], fileName , { type: fileType});

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.attachedFile = {
            // resource: {...response.data, type: fileType, name: fileName, size: fileSize},
            resource: file,
            thumb: reader.result,
            blobSignedId: response.data ? response.data.signed_id : undefined,
            url: URL.createObjectURL(response.data)
          };
        };
      }
    },
    onFileUpload(event) {
      const [file] = event.target.files;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
          this.attachedFile = {
            resource: file,
            thumb: reader.result,
            url: URL.createObjectURL(file)
          };
          event.target.value = ''
        };
    },
    removeAttachment() {
      this.attachedFile = null
    },
    allowedFileTypes() { 
      switch (this.templateType) {
      case 'image':
        return 'image/png, image/jpeg';
      case 'video':
        return 'video/mp4';
      case 'document':
        return 'application/pdf';
      default:
        return '';
      }
    },
    toggleUploadFile() {
      this.$refs.fileInput.click();
    },

    buttonIcon(buttonType) {
            switch (buttonType.toLowerCase()) {
                case 'phone_number':
                    return 'call';
                case 'url':
                    return 'link';
                case 'copy_code':
                    return 'copy';
                case 'quick_reply':
                    return 'arrow-reply';
                default:
                    return '';
            }
        }
  },
};
</script>

<style scoped lang="scss">
.template__variables-container {
  @apply px-2.5 pb-2.5;
}

.variables-label {
  @apply text-sm font-semibold mb-2.5;
}

.template__variable-item {
  @apply items-center flex mb-2.5;

  .label {
    @apply text-xs;
  }

  .variable-input {
    @apply flex-1 text-sm ml-2.5;
  }

  .variable-label {
    @apply bg-slate-75 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
  }
}

footer {
  @apply flex justify-end;

  button {
    @apply ml-2.5;
  }
}
.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
.template-input {
  max-height: 40vh;
  @apply px-2.5 pt-2.5 mb-2.5 overflow-y-auto bg-slate-25 dark:bg-slate-900 text-slate-700 dark:text-slate-100 rounded-sm border border-solid border-slate-75 dark:border-slate-600;
}

.template-input-media {
  max-width: 80%; 
  max-height: 40vh;
}
</style>
