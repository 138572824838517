/* global axios */
import ApiClient from './ApiClient';
import { payloadToFormData } from '../helper/APIHelper'

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create(data) {
    let payload = data;
    if(data.message?.attachments && data.message.attachments.length !== 0) {
      payload = payloadToFormData(data);
    }
    return super.create(payload);
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }
}

export default new ConversationApi();
