<template>
  <woot-dropdown-menu>
    <woot-dropdown-header :title="$t('SIDEBAR.SET_AVAILABILITY_TITLE')" />
    <div class="status-options-menu">
      <woot-dropdown-item
        v-for="status in availabilityStatuses"
        :key="`${status.value}-${status.reasonId || 'default'}`"
        class="flex items-baseline"
      >
        <woot-button
          size="small"
          :color-scheme="status.disabled ? '' : 'secondary'"
          :variant="status.disabled ? 'smooth' : 'clear'"
          class-names="status-change--dropdown-button"
          @click="changeAvailabilityStatus(status.value, status.reasonId, status.label, status.acceptable_time)"
        >
          <timer-popup v-if="showingTimerPopup" :duration="acceptableTime" :label="timerLabel" :currentAccountId="currentAccountId" />
        <availability-status-badge :status="status.value" />
          {{ status.label }}
        </woot-button>
      </woot-dropdown-item>
    </div>
    <woot-dropdown-divider />
    <woot-dropdown-item class="m-0 flex items-center justify-between p-2">
      <div class="flex items-center">
        <fluent-icon
          v-tooltip.right-start="$t('SIDEBAR.SET_AUTO_OFFLINE.INFO_TEXT')"
          icon="info"
          size="14"
          class="mt-px"
        />

        <span
          class="my-0 mx-1 text-xs font-medium text-slate-600 dark:text-slate-100"
        >
          {{ $t('SIDEBAR.SET_AUTO_OFFLINE.TEXT') }}
        </span>
      </div>
      <woot-switch
        size="small"
        class="mt-px mx-1 mb-0"
        :value="currentUserAutoOffline"
        @input="updateAutoOffline"
      />
    </woot-dropdown-item>
    <woot-dropdown-divider />
  </woot-dropdown-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import WootDropdownHeader from 'shared/components/ui/dropdown/DropdownHeader';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider';
import AvailabilityStatusBadge from '../widgets/conversation/AvailabilityStatusBadge';
import wootConstants from 'dashboard/constants/globals';
import TimerPopup from './TimerPopup.vue';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    WootDropdownHeader,
    WootDropdownDivider,
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatusBadge,
    TimerPopup,
  },

  mixins: [clickaway, alertMixin],

  data() {
    return {
      isStatusMenuOpened: false,
      isUpdating: false,
      showingTimerPopup: false,
      reasonId: null,
      timerLabel : '',
      acceptableTime: 0,
    };
  },

  computed: {
    ...mapGetters({
      getCurrentUserAvailability: 'getCurrentUserAvailability',
      currentAccount: 'getCurrentAccount',
      currentAccountId: 'getCurrentAccountId',
      currentUserAutoOffline: 'getCurrentUserAutoOffline',
      reasons_to_be_busy: "reasonsToBeBusy/getReasons",
    }),
    currentUserAvailability() {
      return this.getCurrentUserAvailability;
    },
    availabilityStatuses() {
      const localeStatus = this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES')

      const { reasons_to_be_busy } = this;

      const list = [
        { label: localeStatus.ONLINE, value: 'online' },
        ...(reasons_to_be_busy.length > 0 
          ? reasons_to_be_busy.map(reason => ({ label: reason.description, value: 'busy', reasonId: reason?.id, acceptable_time: reason?.acceptable_time, })) 
          : [{ label: localeStatus.BUSY, value: 'busy', reasonId: null }]
        ),
        { label: localeStatus.OFFLINE, value: 'offline' }
      ];
      return list.map(item => {
        return ({...item, disabled: this.currentAccount.availability === item.value && this.currentAccount.reason_to_be_busy?.id === item.reasonId})}) ;
    },
  },

  methods: {
    openStatusMenu() {
      this.isStatusMenuOpened = true;
    },
    closeStatusMenu() {
      this.isStatusMenuOpened = false;
    },
    updateAutoOffline(autoOffline) {
      this.$store.dispatch('updateAutoOffline', {
        accountId: this.currentAccountId,
        autoOffline,
      });
    },
    changeAvailabilityStatus(availability, reasonId, label, acceptable_time) {
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      try {
        let reason_to_be_busy_label;
        if(availability == "busy"){
          reason_to_be_busy_label = label;
        }else {
          reason_to_be_busy_label = availability;
        }
        this.$store.dispatch('updateAvailability', {
          availability,
          reasonId,
          account_id: this.currentAccountId,
          reason_to_be_busy_label,
        });
        if(availability == "busy"){
          this.acceptableTime = acceptable_time;
          this.timerLabel = label;
          this.showingTimerPopup = true;
        }
        
      } catch (error) {
        this.showAlert(
          this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.SET_AVAILABILITY_ERROR')
        );
      } finally {
        this.isUpdating = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables';

.status-options-menu {
  max-height: 50vh;
  overflow-y: auto;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-micro) var(--space-smaller);
}

.status-view {
  display: flex;
  align-items: baseline;

  & &--title {
    color: var(--b-600);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    margin-left: var(--space-small);

    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.status-change {
  .dropdown-pane {
    top: -132px;
    right: var(--space-normal);
  }

  .status-items {
    display: flex;
    align-items: baseline;
  }
}

.auto-offline--toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--space-smaller);
  margin: 0;

  .info-wrap {
    display: flex;
    align-items: center;
  }

  .info-icon {
    margin-top: -1px;
  }

  .auto-offline--switch {
    margin: -1px var(--space-micro) 0;
  }

  .auto-offline--text {
    margin: 0 var(--space-smaller);
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
    color: var(--s-700);
  }
}
</style>
