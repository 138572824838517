<template>
    <school-form v-if="isKedu" v-bind="$attrs" @success="onSuccess" @cancel="onCancel" />
    <contact-form v-else v-bind="$attrs" @success="onSuccess" @cancel="onCancel" />
</template>
<script>
import configMixin from 'shared/mixins/configMixin';
import SchoolForm from './schoolForm/SchoolForm.vue';
import ContactForm from './ContactForm.vue';

export default {
    components: {
        SchoolForm,
        ContactForm
    },
    mixins: [configMixin],
    methods: {
        onCancel() {
            this.$emit('cancel');
        },
        onSuccess() {
            this.$emit('success');
        }
    }
};

</script>