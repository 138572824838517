/* global axios */
import ApiClient from './ApiClient';

class ReasonsToBeBusyApi extends ApiClient {
  constructor() {
    super('reason_to_be_busies', { accountScoped: true });
  }
}

export default new ReasonsToBeBusyApi();
