import Auth from '../api/auth';

const parseErrorCode = error => Promise.reject(error);

export default axios => {
  const { apiHost = '' } = window.chatwootConfig || {};
  const wootApi = axios.create({ baseURL: `${apiHost}/` });
  // Add Auth Headers to requests if logged in
  if (Auth.hasAuthCookie()) {
    const {
      'access-token': accessToken,
      'token-type': tokenType,
      client,
      expiry,
      uid,
    } = Auth.getAuthData();
    Object.assign(wootApi.defaults.headers.common, {
      'access-token': accessToken,
      'token-type': tokenType,
      client,
      expiry,
      uid,
    });
  }
  // Response parsing interceptor
  wootApi.interceptors.response.use(
    response => response,
    error => parseErrorCode(error)
  );
  return wootApi;
};

export const payloadToFormData = (obj, formData = null, parentKey = null) => {
  formData = formData || new FormData();
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let nestedKey = parentKey ? `${parentKey}[${key}]` : key;
      if (Array.isArray(obj[key])) {
        obj[key].forEach(item => {
          if (typeof item === 'object' && item !== null && !(item instanceof File)) {
            payloadToFormData(item, formData, `${nestedKey}[]`);
          } else {
            formData.append(`${nestedKey}[]`, item);
          }
        });
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (obj[key] instanceof File) {
          formData.append(nestedKey, obj[key]);
        } else {
          payloadToFormData(obj[key], formData, nestedKey);
        }
      } else {
        formData.append(nestedKey, obj[key]);
      }
    }
  }

  return formData;
};
