import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import ReasonsToBeBusyAPI from '../../api/reasonsToBeBusy';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getReasons($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isFetching: true });
    try {
      const response = await ReasonsToBeBusyAPI.get();
      commit(types.SET_REASONS_TO_BE_BUSY, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, reasonToBeBusy) => {
    commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isCreating: true });
    try {
      const response = await ReasonsToBeBusyAPI.create(reasonToBeBusy);
      commit(types.ADD_REASON_TO_BE_BUSY, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { id, ...reasonToBeBusy }) => {
    commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isUpdating: true });
    try {
      const response = await ReasonsToBeBusyAPI.update(id, reasonToBeBusy);
      commit(types.EDIT_REASON_TO_BE_BUSY, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isDeleting: true });
    try {
      await ReasonsToBeBusyAPI.delete(id);
      commit(types.DELETE_REASON_TO_BE_BUSY, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_REASONS_TO_BE_BUSY_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_REASONS_TO_BE_BUSY_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.ADD_REASON_TO_BE_BUSY]: MutationHelpers.setSingleRecord,
  [types.SET_REASONS_TO_BE_BUSY]: MutationHelpers.set,
  [types.EDIT_REASON_TO_BE_BUSY]: MutationHelpers.update,
  [types.DELETE_REASON_TO_BE_BUSY]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
