/* global axios */
import ApiClient from '../ApiClient';

class KeduSchoolEnrollmentsApi extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true, kedu: true });
  }

  get url() {
    return `${this.baseUrl()}/contacts/${this.contactId}/school_enrollments`;
  }

  get(contactId) {
    this.contactId = contactId;
    return super.get();
  }

  create(contactId, content) {
    this.contactId = contactId;
    return super.create({ ...content });
  }

  update(contactId, id, content) {
    this.contactId = contactId;
    return super.update(id, content);;
  }

  addContractFile(contactId, id, content) {
    this.contactId = contactId;
    return axios.post(`${this.url}/${id}/add_contract_file`, content);
  }

  removeContractFile(contactId, id, contractFileId) {
    this.contactId = contactId;
    return axios.delete(`${this.url}/${id}/remove_contract_file`, {params: {contract_file_id: contractFileId}} ); 
  }

  fetchEgdEnrollment(contactId, enrollmentNumber, schoolDocument) {
    this.contactId = contactId;
    return axios.get(`${this.url}/fetch_egd_enrollment`, {params: {enrollment_number: enrollmentNumber, school_document: schoolDocument}});
  }

  delete(contactId, id) {
    this.contactId = contactId;
    return super.delete(id);
  }
}

export default new KeduSchoolEnrollmentsApi();
